import React from 'react'
import styles from './style.module.less'
import LangChange from '../LangChange'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

type Route = {
  title: JSX.Element | string
  paths: string[]
  key: string
  onClick?: () => void
}

function getFullPath(path: string) {
  return process.env.isTestOrDev
    ? `http://34.87.135.238:1083/#${path}`
    : `https://gipr.meet48.com/#${path}`
}

const Navigate: React.FC = () => {
  const { t } = useTranslation()

  const ROUTES: Route[] = [
    { title: 'head.gipr', paths: ['/gipr', '/'], key: 'gipr' },
    { title: 'head.best7', paths: ['/best7'], key: 'best7' },
    { title: 'head.task', paths: ['/task'], key: 'task' },
    { title: 'head.shop', paths: ['/shop'], key: 'shop' },
    { title: 'head.dazzle', paths: ['/dazzle'], key: 'dazzle' }
  ]

  return (
    <div className={styles.navigate}>
      <a
        href={process.env.REACT_APP_MEET48_WEBSITE}
        target="_blank"
        rel="noreferrer"
        className={styles.link}
      >
        HOME
      </a>
      {ROUTES.map((item) => {
        return (
          <div
            key={item.key}
            onClick={() => {
              window.open(getFullPath(item.paths[0]), '_self')
            }}
            className={classNames(styles.link)}
          >
            {typeof item.title === 'string' ? t(item.title) : item.title}
          </div>
        )
      })}
      <LangChange />
    </div>
  )
}

export default React.memo(Navigate)
