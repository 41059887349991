import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { LayerIcon, Logo } from './images'
import { CloseIcon } from '@/assets'
import Dropdown from './components/Dropdown'
import { GLOBAL_CLICK_TYPE, useEvents } from '@/store/useEvents'
import { useHistory } from 'react-router-dom'

const Header: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false)

  const openDropdown = () => setShowDropdown(true)
  const closeDropdown = () => setShowDropdown(false)

  const { registerEvent } = useEvents()

  const history = useHistory()

  const iconSrc = showDropdown ? CloseIcon : LayerIcon

  const onIconClick = () => {
    setShowDropdown((s) => !s)
  }

  useEffect(() => {
    registerEvent(GLOBAL_CLICK_TYPE, closeDropdown)
  }, [])

  return (
    <div className={styles.header} onClick={(e) => e.stopPropagation()}>
      <img
        src={Logo}
        alt="logo"
        className={styles.logo}
        onClick={() => {
          history.push('/')
        }}
      />
      <div className={styles.right}>
        <img src={iconSrc} className={styles.icon} onClick={onIconClick} />
      </div>
      <Dropdown
        show={showDropdown}
        onOpen={openDropdown}
        onClose={closeDropdown}
      />
    </div>
  )
}

export default Header
