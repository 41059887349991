import React from 'react'
import Navigate from './components/Navigate'
import styles from './style.module.less'
import { Logo } from '@/assets'
import NotConnectButton from './components/NotConnectButton'

const Header: React.FC = () => {
  return (
    <div className={styles.header}>
      <div className={styles.main}>
        <div className={styles.left}>
          <img src={Logo} className={styles.logo} />
          <Navigate />
        </div>
        <div className={styles.right}>
          <NotConnectButton />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Header)
