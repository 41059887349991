import React from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { IconDownload } from '@/assets'

function getFullPath() {
  return process.env.isTestOrDev
    ? `http://34.87.135.238:1083/#`
    : `https://gipr.meet48.com/#`
}

const NotConnectButton: React.FC = () => {
  const { t } = useTranslation()

  const loginHandle = async () => {
    window.open(`${getFullPath()}/?login=1`, '_self')
  }

  const registerHandle = () => {
    window.open(`${getFullPath()}/?register=1`, '_self')
  }

  return (
    <div className={styles.notConnectButton}>
      <Button className={styles.btn} onClick={loginHandle} borderOnly>
        {t('normal.login')}
      </Button>
      <Button className={styles.btn} onClick={registerHandle}>
        {t('normal.register')}
      </Button>
      <img
        src={IconDownload}
        className={styles.download}
        onClick={() => {
          window.open(`${getFullPath()}/app`, '_self')
        }}
      />
    </div>
  )
}

export default NotConnectButton
