import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

const LANGS = [
  // { title: '中文', key: 'zh' },
  { title: 'English', key: 'en_US' },
  { title: '繁體中文', key: 'zh_TW' }
]

type Props = {
  show: boolean
}

const Langs: React.FC<Props> = ({ show }) => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(i18n?.languages[0] || 'en_US')

  useEffect(() => {
    // document.documentElement.lang = i18n?.languages[0] || 'en_US'
    setLang(i18n?.languages[0] || 'en_US')
  }, [])

  const handleLangChange = (lang: string) => {
    if (i18n.language === lang) {
      return
    }
    i18n.changeLanguage(lang)
    // document.documentElement.lang = lang
    setLang(lang)
  }

  return (
    <div className={classNames(styles.langs, show && styles.show)}>
      {LANGS.map(({ key, title }) => {
        return (
          <div
            key={key}
            onClick={() => handleLangChange(key)}
            className={classNames(
              styles.langItem,
              lang === key && styles.active
            )}
          >
            {title}
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(Langs)
