import React, { useEffect, lazy, Suspense } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Header from '@/components/AdaptiveHeader'
import styles from './App.module.less'
import { useEvents, GLOBAL_CLICK_TYPE } from '@/store/useEvents'

const LazySearch = lazy(() => import('./Search'))

const App: React.FC = () => {
  const { execEvents } = useEvents()
  useEffect(() => {
    function handle() {
      execEvents(GLOBAL_CLICK_TYPE)
    }
    document.addEventListener('click', handle, false)
    return () => {
      document.removeEventListener('click', handle)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <HashRouter>
        <div className={styles.layout}>
          <Header />
          <Suspense>
            <Switch>
              <Route path="/" component={LazySearch} exact />
            </Switch>
          </Suspense>
        </div>
      </HashRouter>
    </div>
  )
}

export default App
