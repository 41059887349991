import React, { useState } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { Arrow } from '@/assets'
import Langs from '../Langs'

const LangChange: React.FC = () => {
  const [showLangPanel, setShowLangPanel] = useState(false)

  function toggle() {
    setShowLangPanel((s) => !s)
  }

  return (
    <>
      <div className={styles.langCtn} onClick={toggle}>
        <span>EN</span>
        <img
          className={classNames(
            styles.arrow,
            showLangPanel && styles.rotateArrow
          )}
          src={Arrow}
        />
      </div>
      <Langs show={showLangPanel} />
    </>
  )
}

export default React.memo(LangChange)
