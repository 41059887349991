import React from 'react'
import styles from './style.module.less'
import WebHeader from '../WebHeader'
import MobileHeader from '../MobileHeader'

const AdaptiveHeader: React.FC = () => {
  return (
    <div className={styles.adaptiveHeader}>
      <WebHeader />
      <MobileHeader />
    </div>
  )
}

export default React.memo(AdaptiveHeader)
