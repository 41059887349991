import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import Navigate from '../Navigate'
import SignOutInfo from '../SignOutInfo'

type Props = {
  show: boolean
  onOpen: () => void
  onClose: () => void
}

const Dropdown: React.FC<Props> = ({ show, onClose }) => {
  return (
    <div className={classNames(styles.dropdown, show && styles.show)}>
      <Navigate onClose={onClose} />
      <SignOutInfo closeDropdown={onClose} />
    </div>
  )
}

export default React.memo(Dropdown)
