import React from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'

type Props = {
  closeDropdown: () => void
}

function getFullPath() {
  return process.env.isTestOrDev
    ? `http://34.87.135.238:1083/#`
    : `https://gipr.meet48.com/#`
}

const SignOutInfo = ({ closeDropdown }: Props) => {
  const { t } = useTranslation()

  const loginHandle = () => {
    closeDropdown()
    window.open(`${getFullPath()}/?login=1`, '_self')
  }

  const registerHandle = () => {
    closeDropdown()
    window.open(`${getFullPath()}/?register=1`, '_self')
  }

  return (
    <div className={styles.signOutInfo}>
      <Button className={styles.btn} onClick={registerHandle}>
        {t('normal.register')}
      </Button>
      <Button className={styles.btn} onClick={loginHandle} borderOnly>
        {t('normal.login')}
      </Button>
    </div>
  )
}

export default React.memo(SignOutInfo)
